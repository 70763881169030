<template>
    <div>
        <el-button @click="onConnect" v-if="!status">Open Voice Chat</el-button>
        <el-button @click="offConnect" v-if="status">Close Voice Chat</el-button>
    </div>
</template>

<script>
export default {
    name:'voice-chat',
    data(){
        return{
            door:false,
            status:this.value,
            ws:null,
            stream:null
        }
    },
    props:{
        group_id:{
            type:String
        },
        user_id:{
            type:String
        },
        value:{
            type:Boolean
        }
    },
    watch:{
        value(e){
            this.status = e
        }
    },
    methods:{
        onConnect(){
            if(!this.group_id || !this.user_id){
                this.$message.error('Please input your user id and chat room id')
                return
            }
            if(!navigator.mediaDevices.getUserMedia) {
                this.$message.error('Sorry, your browser does not support voice chat');
                return
            }
            //建立连接
            let ws = new WebSocket("wss://106.14.173.221:9000");
            ws.onerror = (err) => {
                this.$message.error('Server Error')
            };
            this.ws = ws
            //成功建立的操作
            ws.onopen = () => {
                this.$message.success('Connection Success')
                this.changeStatus(true)
                ws.send(`user&${this.group_id}&${this.user_id}`);
            };
            //关闭连接的操作
            ws.onclose = ()=>{
                this.changeStatus(false)
            }
            //接收到消息的操作
            ws.onmessage = (e) => {
                audio = new Audio(URL.createObjectURL(e.data)); 
                audio.play()
            };

            
            navigator.mediaDevices.getUserMedia({audio:true,video:false}).then(stream=>{
                this.stream = stream
                this.$message.success('Local voice caught successfully')
                let recorder = new MediaRecorder(stream);
                //语音录制好的操作
                recorder.ondataavailable = (e)=> {
                    console.log('A voice message sent')
                    ws.send(e.data)
                };
                //按下A键说话
                document.onkeydown = (e) => {
                    if(this.status){
                        if(e.keyCode === 65) {
                            if(!this.door) {
                                recorder.start();
                                this.door = true;
                            }
                        }
                    }
                };
            
                document.onkeyup = (e) => {
                    if(this.status){
                        if(e.keyCode === 65) {
                            if(this.door) {
                                recorder.stop();
                                this.door = false;
                            }
                        }
                    }
                }

            }).catch(err=>{
                this.$message.error(err)
            })
        },
        offConnect(){
            if(this.ws) {
                this.ws.close();
                this.changeStatus(false)
                if(this.stream && this.stream.getTracks){
                    this.stream.getTracks().forEach(track=>track.stop())
                }
            }
        },
        changeStatus(bool){
            this.status = bool
            this.$emit('input',bool)
        }
    },
    destroyed(){
        this.offConnect()
    },
    
}
</script>

<style>

</style>