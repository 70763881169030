<template>
  <div>
    <p class="qa_title">1. What actions will be judged by the system as screen cutting?</p>
    <p class="qa_content">
      System pop-ups (including advertisements, computer system prompts, social message pop-ups, etc.), exiting the browser to full screen, minimizing the browser, clicking on other places outside the browser window, using browser page translation tools, using chat software, opening other browsers except Google Chrome, opening anti-virus software such as Security Guard, Computer Manager software, etc. Mac OS's own mouse-down menu bar function will also be judged as screen cut by the system.
    </p>
    <p class="qa_title" style="margin-top: 12px">2. How many screen cuts are considered cheating? </p>
    <p class="qa_content">If screen cutting is detected, the system will automatically record it and determine whether there is cheating based on the number, frequency and interval of screen cutting. If the system determines that there is cheating, the system will force you to turn in the test automatically, regardless of whether you finish answering all the questions.</p>

    <p class="qa_title" style="margin-top: 12px">3. Why is the mouse cursor not displayed on the page?</p>
    <p class="qa_content">In order to avoid players using the mouse to translate words in the page using the relevant plug-ins, the mouse cursor will be hidden when it stays on the page, and the corresponding option can be selected by moving the mouse to the circle in front of the option.
The mouse cursor can be displayed normally when switching between questions (previous question/next question).</p>

    <p class="qa_title" style="margin-top: 12px">4. Can I go to the bathroom during the test?</p>
    <p class="qa_content">You are allowed to go to the restroom while the camera is on, and you need to return to the camera within 1-2 minutes.
</p>

    <p class="qa_title" style="margin-top: 12px">5. Is it possible to turn in the exam early?</p>
    <p class="qa_content">You can turn in your exam early.</p>

    <p class="qa_title" style="margin-top: 12px">6. Can the page be refreshed if the page is suddenly blank/black/stalled?</p>
    <p class="qa_content">You can refresh the page. Please check the network status before refreshing.</p>

    <p class="qa_title" style="margin-top: 12px">7. What if I close the page by mistake? Will the answer records be saved?</p>
    <p class="qa_content">If you close the page by mistake, you can go back to the home page and enter the test again; in the test environment where the player is located, the answer record will be saved in real time when the network is normal and smooth, please check the answer status of each question after entering the test again.</p>

    <p class="qa_title" style="margin-top: 12px">8. Can team members answer questions at the same time?</p>
    <p class="qa_content">Team members can answer questions at the same time, but the data operated by team members in different computer terminals is the same data on the server, that is, the answers of different team members to the same question will be overwritten in order of priority, and it will not actively notify other team members when there is overwriting and modification. Therefore, it is recommended that team members should divide and coordinate their work directly, and designate a certain member to make changes.</p>


  </div>
</template>

<script>
export default {
  name: "QA"
}
</script>

<style scoped>
.qa_title {
  color: #484949;
  font-size: 16px;
  font-weight: bold;
}

.qa_content {
  margin-top: 10px;
  color: #707170;
  font-size: 14px;
  line-height: 22px;
}

</style>