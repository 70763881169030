<template>
  <div class="exam-root">
    <div class="topBanner">
      <div style="position: absolute; z-index: 2; width: 100%; height: 100%">
        <img
          src="../../assets/newNavAllBg.png"
          style="width: 100%; height: 100%; object-fit: fill"
        />
      </div>
      <!-- 756 × 136 -->
      <div
        style="
          margin-left: 150px;
          position: absolute;
          z-index: 3;
          width: 189px;
          height: 34px;
        "
      >
        <img
          src="../../assets/newNavBg.png"
          style="width: 100%; height: 100%; object-fit: fill"
        />
      </div>

      <div class="logoutStyle" @click="changeView()">Logout</div>
    </div>

    <div class="topBanner1">
      <div class="countryStyle">
        {{ student_school }}
      </div>

      <div class="nameStyle">
        {{ student_name }}
      </div>

      <Invigilate
        v-if="!DEBUG_DEV && signStatus"
        v-model="status"
        :template_id="template_id"
        @invigilateCallback="invigilateCallback"
        @visibleChange="refreshVisible"
        class="inviStyle"
      ></Invigilate>

      <div class="changePMNumStyle">
        {{ getShowWord("exchange_screen_counts") }}: {{ screenChangeCount }}
        <i @click="showExchangeTip" class="el-icon-info"></i>
        <!-- {{getShowWord('exchange_screen_counts')}}: {{ screenChangeCount + ' / ' + change_screen_num }} -->
      </div>

      <div class="exam-submit1" @click="doSubmitAnswer">
        {{ getShowWord("submit_exam") }}
      </div>

      <div class="countDownNewStyle">
        {{ countDownTimes }}
      </div>
    </div>

    <!-- <div class="exam-top">
      <img src="../../assets/arrow_circle_back.png" style="cursor: pointer;width: 3.5vw;height: auto" @click="goBack">
      <div style="margin-left: 2.6vw;text-align: left;">
        <div style="color: white;font-size: 1.6vw;font-weight: 600;font-family: Montserrat-SemiBold">
          2021-2022 FBLA China<br>
          National Leadership Conference and Economics Challenge<br>
          {{ test_subject }}
        </div>
      </div>
      <div
          style="margin-left: 5vw;align-items: center;display: flex;flex-direction: row;">
        <div class="exam-count-down">
          <div
              style="margin-left: -1vw;;font-weight: bold;font-size: 1.14vw;color: white;text-align: center;margin-top: 0.46vh;font-family: 'Microsoft YaHei-Bold'">
            {{getShowWord('count_down')}}
          </div>
          <div
              style="color: #2D50B0;font-size: 1.56vw;margin-left: -1vw;margin-top: 1.656vh;letter-spacing: 0.16vw;font-family: Montserrat-SemiBold">
            {{ countDownTimes }}
          </div>
        </div>
        <div>
          <div
              style="margin-left:40px;margin-bottom:10px;background-color:white;padding:6px;border-radius:4px;font-size:12px;color:red">
            {{getShowWord('exchange_screen_counts')}}: {{ screenChangeCount }}

          </div>
          <div class="exam-submit" @click="doSubmitAnswer">{{getShowWord('submit_exam')}}</div>

        </div>
        <div class="exam-btn-exchange-lang" @click="doExchangeLang">{{getShowWord('exchange_lang')}}</div>
      </div>
    </div> -->
    <!-- <Invigilate v-if="!DEBUG_DEV" v-model="status" :template_id="template_id"
                @invigilateCallback="invigilateCallback" @visibleChange="refreshVisible"
                style="height: 6vh"></Invigilate> -->
    <div class="exam-content" id="exam-content" v-if="sessionSelect != 0">
      <!-- <div class="exam-empty-width-view"/> -->
      <div style="flex: 2; display: flex; flex-direction: column">
        <!-- <div class="exam-empty-height-view"/> -->
        <div
          style="
            width: 100%;
            height: 100%;
            flex: 2;
            display: flex;
            flex-direction: column;
          "
        >
          <div v-if="canExam" class="exam-content-page">
            <div style="flex: 2; width: 100%; height: 100%">
              <div id="exam-image" class="exam-image">
                <div v-for="(url, index) in exam_images" :key="index">
                  <el-image
                    :src="url"
                    oncontextmenu="return false;"
                    style="width: 100%; height: auto; cursor: none"
                    :preview-src-list="exam_images"
                    lazy
                  >
                    <div
                      slot="placeholder"
                      style="
                        width: 100%;
                        height: 100%;
                        background-color: transparent;
                      "
                    >
                      <div
                        style="
                          color: #072d76;
                          font-size: 30px;
                          font-family: Montserrat-SemiBold;
                          margin-top: 100px;
                        "
                      >
                        加载中<span class="dot">...</span>
                      </div>
                    </div>
                    <div
                      slot="error"
                      style="
                        width: 100%;
                        height: 100%;
                        background-color: transparent;
                        cursor: pointer;
                      "
                      @click="reLoadImage(url)"
                    >
                      <div
                        style="
                          color: #072d76;
                          font-size: 20px;
                          font-family: Montserrat-SemiBold;
                          margin-top: 100px;
                        "
                      >
                        图片加载失败，点击请重试<span class="dot">...</span>
                      </div>
                    </div>
                  </el-image>
                  <div
                    style="
                      position: absolute;
                      left: 25px;
                      top: 25px;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    <span
                      style="
                        color: white;
                        font-size: 18px;
                        font-weight: bold;
                        cursor: pointer;
                      "
                      @click="showBigPic(url)"
                      >点击查看大图</span
                    >
                    <span
                      style="
                        margin-left: 25px;
                        color: white;
                        font-size: 18px;
                        font-weight: bold;
                        cursor: pointer;
                      "
                      @click="reLoadImage(url)"
                      >点击刷新图片</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="exam-page-bottom">
              <div class="exam-page-bottom">
                <div class="exam-page-bottom-text" @click="showPreImage">{{getShowWord('page_pre')}}</div>
                <div class="exam-page-bottom-text">
                  {{ examImageSize > 0 ? ((currentSelectedImageIndex + 1) + '/' + examImageSize) : '0/0' }}
                </div>
                <div class="exam-page-bottom-text" @click="showNextImage">{{getShowWord('page_next')}}</div>
              </div>
              <div class="exam-goto-page" @click="beforeShowSelectImageDialog">{{getShowWord('gotoPage')}}</div>
            </div> -->
          </div>
        </div>
      </div>
      <!-- <div class="exam-empty-width-view"/> -->
      <div style="flex: 1; display: flex; flex-direction: column">
        <!-- <div class="exam-empty-height-view"/> -->
        <div
          style="
            width: 100%;
            height: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
          "
        >
          <div v-if="canExam" class="exam-content-page">
            <div style="flex: 1; width: 100%; height: 100%">
              <div class="exam-question-content">
                <div class="wrapper">
                  <div
                    v-for="(v, index) in questionList"
                    :key="index"
                    :class="v.choice ? 'liStyle' : 'liStyle1'"
                    @click="itemSelectQuestion(index)"
                  >
                    {{ index + 1 }}
                  </div>
                </div>

                <div style="margin: 20px">
                  <div class="questionTitle">
                    <div
                      v-html="
                        currentSelectedQuestionIndex +
                        1 +
                        '. ' +
                        currentSelectedQuestion['question_question']
                      "
                    ></div>
                  </div>
                  <div
                    class="option"
                    v-if="
                      currentSelectedQuestion.question_optionA &&
                      currentSelectedQuestion.question_optionA != ''
                    "
                  >
                    <el-radio
                      :disabled="lockStatus"
                      @change="saveAnswer('A', currentSelectedQuestion)"
                      :value="
                        sections[sectionSelect].questions_item[
                          currentSelectedQuestionIndex
                        ].user_answer
                      "
                      label="A"
                    >
                      <span class="optionTitle">A</span>
                    </el-radio>
                    <div class="div_p">
                      <div
                        :class="{
                          selected: currentSelectedQuestion.choice == 'A',
                        }"
                        v-html="currentSelectedQuestion.question_optionA"
                      ></div>
                    </div>
                  </div>
                  <div
                    class="option"
                    v-if="
                      currentSelectedQuestion.question_optionB &&
                      currentSelectedQuestion.question_optionB != ''
                    "
                  >
                    <el-radio
                      :disabled="lockStatus"
                      @change="saveAnswer('B', currentSelectedQuestion)"
                      :value="
                        sections[sectionSelect].questions_item[
                          currentSelectedQuestionIndex
                        ].user_answer
                      "
                      label="B"
                    >
                      <span class="optionTitle">B</span>
                    </el-radio>
                    <div class="div_p">
                      <div
                        :class="{
                          selected: currentSelectedQuestion.choice == 'B',
                        }"
                        v-html="currentSelectedQuestion.question_optionB"
                      ></div>
                    </div>
                  </div>
                  <div
                    class="option"
                    v-if="
                      currentSelectedQuestion.question_optionC &&
                      currentSelectedQuestion.question_optionC != ''
                    "
                  >
                    <el-radio
                      :disabled="lockStatus"
                      @change="saveAnswer('C', currentSelectedQuestion)"
                      :value="
                        sections[sectionSelect].questions_item[
                          currentSelectedQuestionIndex
                        ].user_answer
                      "
                      label="C"
                    >
                      <span class="optionTitle">C</span>
                    </el-radio>
                    <div class="div_p">
                      <div
                        :class="{
                          selected: currentSelectedQuestion.choice == 'C',
                        }"
                        v-html="currentSelectedQuestion.question_optionC"
                      ></div>
                    </div>
                  </div>
                  <div
                    class="option"
                    v-if="
                      currentSelectedQuestion.question_optionD &&
                      currentSelectedQuestion.question_optionD != ''
                    "
                  >
                    <el-radio
                      :disabled="lockStatus"
                      @change="saveAnswer('D', currentSelectedQuestion)"
                      :value="
                        sections[sectionSelect].questions_item[
                          currentSelectedQuestionIndex
                        ].user_answer
                      "
                      label="D"
                    >
                      <span class="optionTitle">D</span>
                    </el-radio>
                    <div class="div_p">
                      <div
                        :class="{
                          selected: currentSelectedQuestion.choice == 'D',
                        }"
                        v-html="currentSelectedQuestion.question_optionD"
                      ></div>
                    </div>
                  </div>
                  <div
                    class="option"
                    v-if="
                      currentSelectedQuestion.question_optionE &&
                      currentSelectedQuestion.question_optionE != ''
                    "
                  >
                    <el-radio
                      :disabled="lockStatus"
                      @change="saveAnswer('E', currentSelectedQuestion)"
                      :value="
                        sections[sectionSelect].questions_item[
                          currentSelectedQuestionIndex
                        ].user_answer
                      "
                      label="E"
                    >
                      <span class="optionTitle">E</span>
                    </el-radio>
                    <div class="div_p">
                      <div
                        :class="{
                          selected: currentSelectedQuestion.choice == 'E',
                        }"
                        v-html="currentSelectedQuestion.question_optionE"
                      ></div>
                    </div>
                  </div>
                </div>

                <div style="width: 100%">
                  <div class="lastClickStyle" @click="showPreQuestion">
                    Previous
                  </div>
                  <div class="nextClickStyle" @click="showNextQuestion">
                    Next
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="exam-page-bottom">
              <div class="exam-page-bottom">
                <div class="exam-page-bottom-text" @click="showPreQuestion">{{getShowWord('question_pre')}}</div>
                <div class="exam-page-bottom-text">
                  {{ examQuestionSize > 0 ? ((currentSelectedQuestionIndex + 1) + '/' + examQuestionSize) : '0/0' }}
                </div>
                <div class="exam-page-bottom-text" @click="showNextQuestion">{{getShowWord('question_next')}}</div>
              </div>
              <div class="exam-menu" @click="showMenu">{{getShowWord('menu')}}</div>
              <div class="exam-menu" style="width: 6vw;background-color: #00D523" @click="showMessage">
                <div class="redTip" v-if="showRedTip"></div>
                {{getShowWord('question')}}
              </div>
              <div class="exam-menu" @click="showQADrawer=true">FAQ</div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="exam-empty-width-view" />
    </div>

    <div class="exam-list" v-if="sessionSelect == 0">
      <div style="clear: both"></div>
      <div
        v-if="section_list_instruction != ''"
        class="section_list_instruction"
      >
        NOTE: {{ section_list_instruction }}
      </div>
      <div
        v-for="(item, index) in sections"
        :key="index"
        class="sessionStyle"
        @click="showExamWith(index)"
      >
        <div style="">
          <div>
            <div class="sesionNumStyle">CASE</div>
            <div class="sesionNumStyle4">
              {{ index + 1 > 10 ? index + 1 : "0" + (index + 1) }}
            </div>
            <div class="sesionLineStyle"></div>

            <div class="sesionNumStyle1">{{ item.section_subtitle }}</div>
          </div>

          <div class="sesionNumStyle5">{{ item.section_title }}</div>
          <!-- <div class="sesionNumStyle3">{{item.section_instruction}}</div> -->
        </div>
      </div>
    </div>

    <div class="bottomView">
      <div class="exam-menu-new">
        <div
          class="button"
          v-if="sessionSelect != 0"
          @click="selectSessionClick"
        >
          Back
          <!-- {{sessionSelectEvent}} -->
        </div>

        <div class="button" @click="showTeamChatDrawer = true">Team Chat</div>
        <div class="redTipNew" v-if="showRedTip"></div>
        <div class="button" @click="showMessage">
          {{ getShowWord("question") }}
        </div>
        <!-- <div class="button" @click="openTextChat">{{getShowWord('team_chat')}}</div> -->
        <div class="button" @click="showQADrawer = true">FAQ</div>
        <div
          v-show="!isChrome"
          style="color: white; font-size: 12px; text-align: right"
        >
          ALERT: you are NOT using Google Chrome to visit this site, which might
          cause unexpected misfunctions or bugs. The developer of this system
          requires visiting via Chrome and will not be responsible for any
          errors on non-Chrome platforms.
        </div>
      </div>
      <!-- <div class="exam-menu-new1" @click="showQADrawer=true">FAQ</div> -->
    </div>
    <el-dialog
      :visible.sync="showSubmitAnswerDialog"
      :modal="true"
      :show-close="false"
      :modal-append-to-body="true"
      custom-class="showSubmitAnswerDialogStyle"
    >
      <div class="submitAnswer-root">
        <div style="position: absolute; right: 1vw; top: 1vw">
          <!-- <img src="../../assets/close_1.png" style="cursor: pointer;width: 43px;height: auto"
               @click="showSubmitAnswerDialog=false"> -->
        </div>
        <div
          style="
            width: 100%;
            justify-content: center;
            display: flex;
            flex-direction: column;
            position: absolute;
            bottom: 5vh;
          "
        >
          <div
            style="
              color: #003262;
              font-size: 1.2vw;
              font-weight: 600;
              padding: 20px;
              text-align: left;
              line-height: 2vw;
            "
          >
            Confirm to Submit Answers? Please note the submission is <br />for
            the whole test for the whole team. You do NOT need to <br />
            submit for a single case. Once you submit, your team answers
            <br />will be final and your teammates\' later submissions will not
            be successful
          </div>
          <div
            style="
              margin-top: 4vh;
              display: flex;
              justify-content: center;
              flex-direction: row;
              height: 5vh;
              line-height: 5vh;
            "
          >
            <div
              @click="uploadAnswers"
              style="
                font-weight: 600;
                font-size: 1.67vw;
                cursor: pointer;
                color: #003262;
                border: 1px solid #003262;
                width: 9vw;
                background-color: white;
                border-radius: 31px;
                text-align: center;
              "
            >
              {{ getShowWord("btn_confirm") }}
            </div>

            <div
              @click="showSubmitAnswerDialog = false"
              style="
                font-weight: 600;
                font-size: 1.67vw;
                cursor: pointer;
                margin-left: 40px;
                color: white;
                width: 9vw;
                background-color: #003262;
                border-radius: 31px;
                text-align: center;
              "
            >
              {{ getShowWord("btn_cancel") }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-drawer
      :modal="true"
      :show-close="false"
      :modal-append-to-body="true"
      :visible.sync="showDrawer"
      direction="rtl"
      :withHeader="false"
      size="60%"
      custom-class="showMenuDialogStyle"
    >
      <div class="menu-content">
        <div style="margin-top: 100px">
          <img
            src="../../assets/drawer-left.png"
            style="cursor: pointer; width: 28px; height: auto"
            @click="showDrawer = false"
          />
        </div>
        <div class="drawer-question-list" style="margin-left: -2px">
          <div style="padding-top: 30px; padding-bottom: 15px">
            <p
              style="
                letter-spacing: 3px;
                color: white;
                font-size: 20px;
                font-weight: bold;
                font-family: Montserrat-SemiBold;
              "
            >
              {{ drawerAnswerInfo }}
            </p>
          </div>
          <div style="width: 100%; height: 100%; overflow-y: scroll">
            <el-tabs v-model="activeName" stretch="true">
              <el-tab-pane :label="getShowWord('all_questions')" name="first">
                <div>
                  <div
                    v-for="(item, index) in drawerQuestionList"
                    :key="index"
                    class="drawer-question-item-root"
                    @click="doSelectQuestion(index)"
                  >
                    <div
                      :class="{
                        'drawer-question-item': true,
                        selected: item.choice !== '',
                      }"
                    >
                      <div style="flex: 1; display: flex; flex-direction: row">
                        <div
                          v-html="item.question_question"
                          style="
                            margin-left: 10px;
                            text-align: left;
                            font-size: 14px;
                          "
                        ></div>
                      </div>
                      <div class="drawer-answered" v-if="item.choice !== ''">
                        {{ getShowWord("answered") }}
                      </div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane :label="getShowWord('not_answer')" name="second">
                <div>
                  <div
                    v-for="(item, index) in drawerNoChoiceQuestionList"
                    :key="index"
                    class="drawer-question-item-root"
                    @click="doSelectQuestion(item['parentIndex'])"
                  >
                    <div :class="{ 'drawer-question-item': true }">
                      <div style="flex: 1; display: flex; flex-direction: row">
                        <div
                          v-html="item.question_question"
                          style="margin-left: 10px; text-align: left"
                        ></div>
                      </div>
                      <!--                      <div class="drawer-answered" v-if="item.choice !==''">-->
                      <!--                        已做答-->
                      <!--                      </div>-->
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </el-drawer>
    <el-dialog :visible.sync="showBigPicDialog" width="100%">
      <img :src="currentSelectedPicPath" />
    </el-dialog>
    <el-dialog
      :visible.sync="showAnswerResultDialog"
      width="100%"
      :modal="true"
      :show-close="false"
      :modal-append-to-body="true"
      custom-class="showAnswerResultDialogStyle"
    >
      <div class="answer-result-root">
        <div class="awardTitle">Congratulations</div>
        <div class="awardTitle1">{{ answerResultDta.application_event }}</div>
        <div class="scoreLab2">
          {{
            answerResultDta.total_score + "   /   " + answerResultDta.full_score
          }}
        </div>
        <div class="scoreLab1">
          * Information about rankings, advancements and awards will be notified
          shortly via emails after all score and invigilation reviews are
          complete.
        </div>

        <!-- <div class="awardLine"></div>
        <div class="awardSubTitle">You have learnt and mastered the following knowledge, concepts and theories:</div>
        <div class="box">
          <div v-for="(item,index) in answerResultDta.achievements" :key="index" class="child">
            <img class="infoImg" src="../../assets/selectInfoImage.png">
            <div class="infoStyle">{{ '&nbsp;&nbsp;' + item + '&nbsp;&nbsp;' }}</div>
          </div>
        </div> -->
        <div @click="closeView" class="returnStyle">Return Home</div>
      </div>
    </el-dialog>
    <el-drawer
      title="Enquiries"
      :visible.sync="messageDrawer"
      direction="ltr"
      size="500px"
      :before-close="handleClose"
    >
      <!-- <span>消息列表</span> -->

      <div class="messageList">
        <div class="inputStyle">
          <el-select
            type="text"
            class="inputSelect"
            v-model="messageType"
            style="width: 250px"
          >
            <el-option value="学术问题" label="Academic"></el-option>
            <el-option value="流程问题" label="Process"></el-option>
            <el-option value="系统操作问题" label="System"></el-option>
            <el-option value="数据问题" label="Data"></el-option>
            <el-option value="奖项证书问题" label="Awards"></el-option>
            <el-option value="其他问题" label="Others"></el-option>
          </el-select>

          <el-input v-model="messageStr" class="inputStyle1"></el-input>
          <el-button class="sendStyle" @click="sendMessage">Send</el-button>
        </div>

        <div style="margin-top: 20px; height: 20px"></div>

        <div
          v-for="(x, index) in messageList"
          :key="index"
          class="messageDetail"
        >
          <!-- <div style="color:#001D9F;font-size:15px">
            {{ x.type }}
          </div> -->
          <div style="color: #00359f; font-size: 13px; margin-top: 10px">
            {{ x.message_content }}
          </div>

          <div
            v-if="x.reply_content != ''"
            style="color: #00359f; font-size: 13px; margin-top: 10px"
          >
            {{ "REPLY：" + x.reply_content }}
          </div>

          <div
            style="background-color: #00359f; height: 1px; margin-top: 10px"
          ></div>
        </div>
      </div>
    </el-drawer>
    <el-dialog :visible.sync="showGotoPageDialog" title="Tips" width="30%">
      <div>
        {{ getShowWord("gotoPage") }}
        <el-input-number
          v-model="gotoImageIndex"
          :min="1"
          :max="examImageSize"
          @change="handleGotoImagChanged"
        ></el-input-number>
        / {{ examImageSize }}
      </div>
      <div style="margin-top: 25px">
        <el-button size="small" type="primary" @click="gotoSelectedImage">
          {{ getShowWord("btn_confirm") }}</el-button
        >
        <el-button
          size="small"
          style="margin-left: 20px"
          @click="showGotoPageDialog = false"
        >
          {{ getShowWord("btn_cancel") }}</el-button
        >
      </div>
    </el-dialog>
    <el-drawer
      title="Frequently Asked Questions"
      :visible.sync="showQADrawer"
      direction="rtl"
      size="40%"
    >
      <div style="text-align: left; margin: 15px">
        <QA />
      </div>
    </el-drawer>
    <el-drawer
      title="Team Chat"
      :visible.sync="showTeamChatDrawer"
      direction="ltr"
      size="500px"
      :before-close="handleClose"
    >
      <TextChat
        v-model="socketStatus"
        :app_id="app_id"
        :user_id="user_id"
        @getWsOut="onGetWsOut"
        @answerSync="onAnswerSync"
        @correctQuestionSync="onCorrectQuestionSync"
        @correctSessionPicSync="onCorrectSessionPicSync"
      ></TextChat>
    </el-drawer>
  </div>
</template>

<script>
import {
  getExerciseTemplateByStudent,
  getMyMessages,
  getTestVisibleChangeCount,
  sendMessage,
  submitAnswer,
  uploadAnswersV3,
  checkIsUploadTest,
  uploadTestHeartBeat,
  teamSignWithTemplate,
  getGroupId,
  getTeamMembers,
} from "../../api/index";
import "../../assets/common/font.css";
import {
  getAppId,
  getProjectCode,
  getUserId,
  getUserIP,
  getUserName,
  getAccountName,
  logout,
  getTestEvent,
  getBrowserType,
} from "../../utils/store";
import Invigilate from "./Invigilate";
import VoiceChat from "../CommonChat/voice.vue";
import TextChat from "../CommonChat/txt.vue";
import QA from "./QA";
import scroll from "../../utils/scroll";
import { Loading } from "element-ui";
export default {
  name: "StudentExam",
  components: {
    Invigilate,
    QA,
    VoiceChat,
    TextChat,
  },
  watch: {
    status(newStatus, oldStatus) {
      if (!newStatus) {
        //禁止做题目了
        this.canExam = false;
        console.log("---sorry--");
        this.ishouldRefreshView = true;
        this.clearCountDown();
        //停止一切
      } else {
        console.log("---yes --");
        this.canExam = true;
        this.fetchData();
      }
    },
  },
  data() {
    return {
      questionAnswers: {},
      ishouldRefreshView: false,
      test_event: getTestEvent(),
      showTeamChatDrawer: false,
      showQADrawer: false,
      gotoImageIndex: 1,
      showGotoPageDialog: false,
      activeName: "first",
      canExam: true,
      answerResultDta: {},
      showAnswerResultDialog: false,
      showAnswerResultClick: false, // return home 返回事件也会被捕捉到
      showDrawer: false,
      messageDrawer: false,
      showSubmitAnswerDialog: false,
      user_id: getUserId(),
      template_id: "",
      test_subject: "",
      countDownThread: undefined,
      sections: [],
      countDownTimes: "00:00:00",

      remainStamp: 9000,
      status: false,
      exam_images: [],
      currentSelectedImageIndex: 0,
      examImageSize: 0,

      currentSelectedQuestionIndex: 0,
      questionList: [],
      drawerQuestionList: [],
      drawerChoiceQuestionList: [],
      drawerNoChoiceQuestionList: [],
      examQuestionSize: 0,
      currentSelectedQuestion: {
        question_id: "",
        question_index: "",
        question_question: "",
        question_optionA: "",
        question_optionB: "",
        question_optionC: "",
        question_optionD: "",
        question_optionE: "",
        choice: "",
      },
      showBigPicDialog: false,
      currentSelectedPicPath: "",
      app_id: getAppId(),
      deviceStatus: 0,
      DEBUG_DEV: true,
      drawerAnswerInfo: "",
      project_code: "",
      ip: "",
      messageList: [],
      messageType: "You're enquiring about?",
      messageStr: "",
      timer: "",
      showRedTip: false,
      screenChangeCount: 0,
      heartBeatTimer: undefined,
      currentLang: "英文",
      WORDS: {
        中文: {
          exchange_lang: "English",
          page_pre: "上一页",
          page_next: "下一页",
          question_pre: "上一题",
          question_next: "下一题",
          menu: "目录",
          question: "咨询",
          submit_exam: "提交试卷",
          count_down: "倒计时",
          exchange_screen_counts: "切屏次数",
          btn_cancel: "取消",
          btn_confirm: "确定",
          all_questions: "所有",
          not_answer: "未答",
          answered: "已答",
          gotoPage: "跳转到",
          proctoring: "监考中",
          team_chat: "组内聊天",
        },
        英文: {
          exchange_lang: "中文",
          page_pre: "Previous Page",
          page_next: "Next Page",
          question_pre: "Previous Question",
          question_next: "Next Question",
          menu: "Menu",
          question: "Enquiries",
          submit_exam: "Submit",
          count_down: "Count Down",
          exchange_screen_counts: "Screen Cuts",
          btn_cancel: "Cancel",
          btn_confirm: "Confirm",
          all_questions: "All Questions",
          not_answer: "Not Answers",
          answered: "Answered",
          gotoPage: "Go To",
          proctoring: "Under Proctoring",
          team_chat: "Team Chat",
        },
      },
      student_name: "",
      student_school: "",
      sessionSelect: 0,
      sectionSelect: 0,
      sessionSelectEvent: "",
      socketStatus: false,
      group_id: "",
      forceShutDown: false,
      section_list_instruction: "",
      ws: null,
      isChrome: false,
      lockStatus: false, //锁定状态，无法选择答案
      signStatus: false,
    };
  },
  created() {
    window.addEventListener("offline", this.networkErrorCallback);
    window.addEventListener("online", this.networkSuccessCallback);
  },
  async mounted() {
    // this.student_name = getUserName()
    // this.student_school = getAccountName()
    let browser = getBrowserType();
    if (browser === "CHROME") {
      this.isChrome = true;
    }
    this.project_code = getProjectCode();
    this.app_id = getAppId();
    this.template_id = this.$route.query.template_id;
    this.user_id = getUserId();

    //先签到
    let result = await this.studentSignIn();

    if (result) {
      this.signStatus = true;
    } else {
      return;
    }

    getGroupId(this.app_id).then((res) => {
      this.group_id = res.data;
    });
    if (this.DEBUG_DEV) {
      this.fetchData();
    }
    getUserIP((ip) => {
      console.log(2222, ip);
      this.ip = ip;
    });

    this.getMyMessages();
    this.refreshVisible();

    // this.timer = setInterval(this.showRedMessage, 5000);

    setTimeout(() => {
      this.pushHistory();
      window.addEventListener("popstate", this.goBack, false);
      window.history.pushState("forward", null, ""); //在IE中必须得有这两行
      window.history.forward(1);
    }, 1000);

    this.checkStudentExam();

    // this.$nextTick(() => {
    //   // 禁用右键
    //   document.oncontextmenu = new Function("event.returnValue=false");
    //   // 禁用选择
    //   document.onselectstart = new Function("event.returnValue=false");
    // });

    this.getTeamMembers();
  },
  methods: {
    reLoadImage(url) {
      var time = new Date().getTime();
      let new_url = url + "?" + time;
      this.exam_images[this.currentSelectedImageIndex] = new_url;
    },
    onGetWsOut(e) {
      this.ws = e;
    },
    openTextChat() {
      this.showTeamChatDrawer = true;
    },
    pushHistory() {
      history.pushState(null, null, document.URL);
    },

    getTeamMembers() {
      getTeamMembers(getProjectCode(), getUserId()).then((res) => {
        var result = res.data.data;

        this.student_name = result.team_names.join(" ");
        this.student_school = result.country;
      });
    },
    changeView() {
      this.$confirm("Are you sure you want to exit?", "Tips", {
        confirmButtonText: "Confirm to EXIT",
        cancelButtonText: "cancel",
        type: "warning",
      }).then(() => {
        this.logout();
      });
    },
    logout() {
      logout();
      location.reload();
    },
    invigilateCallback() {},
    showExamWith(index) {
      this.sessionSelect = index + 1;
      this.sectionSelect = index;

      var sectionDetail = this.sections[index];

      this.sessionSelectEvent = sectionDetail.section_title;

      var pic_arr = sectionDetail.pic_arr;
      this.exam_images = [];
      for (var m = 0; m < pic_arr.length; m++) {
        this.exam_images.push("https://" + pic_arr[m]);
      }

      var questionArr = [];

      var questions_items = sectionDetail["questions_item"];
      for (var j = 0; j < questions_items.length; j++) {
        index++;
        var pos = index;
        if (pos < 10) {
          pos = "0" + pos;
        }
        var question = {
          question_id: questions_items[j]["_id"]["$id"],
          question_index: pos,
          question_question: questions_items[j]["question_question"],
          question_optionA: questions_items[j]["question_optionA"],
          question_optionB: questions_items[j]["question_optionB"],
          question_optionC: questions_items[j]["question_optionC"],
          question_optionD: questions_items[j]["question_optionD"],
          question_optionE: questions_items[j]["question_optionE"],
          choice: questions_items[j]["user_answer"],
        };
        questionArr.push(question);
      }

      this.questionList = questionArr;
      var section_id = sectionDetail._id.$id;

      this.drawerQuestionList = this.questionList;
      this.examQuestionSize = this.questionList.length;
      this.currentSelectedQuestionIndex = 0;
      this.currentSelectedQuestion = this.questionList[0];
    },
    selectSessionClick() {
      this.sessionSelect = 0;
      this.sessionSelectEvent = "";
    },
    networkSuccessCallback() {
      this.$message({
        message: "Connected!",
        type: "success",
        duration: 5000,
        showClose: true,
      });
    },
    networkErrorCallback() {
      this.$message({
        message: "Connection Error, please check your network!",
        type: "error",
        duration: 5000,
        showClose: true,
      });
    },
    handleGotoImagChanged(value) {
      //图片不需要跟着一起切换
      // this.currentSelectedImageIndex = this.gotoImageIndex-1
    },
    beforeShowSelectImageDialog() {
      this.gotoImageIndex = this.currentSelectedImageIndex + 1;
      this.showGotoPageDialog = true;
    },
    gotoSelectedImage() {
      this.resetViewPosition();
      this.currentSelectedImageIndex = this.gotoImageIndex - 1;
      this.showGotoPageDialog = false;
    },

    doExchangeLang() {
      if (this.currentLang === "中文") {
        this.currentLang = "英文";
      } else {
        this.currentLang = "中文";
      }
    },
    getShowWord(value) {
      return this.WORDS[this.currentLang][value];
    },

    studentSignIn() {
      return new Promise((resolve, reject) => {
        teamSignWithTemplate(
          this.user_id,
          this.template_id,
          this.project_code
        ).then((res) => {
          var result = res.data.data;
          var begin_time = result.begin_time;
          var end_time = result.end_time;
          var test_duration = result.test_duration;

          console.log("begin_time :" + begin_time);
          console.log("end_time :" + end_time);
          console.log("test_duration :" + test_duration);

          var currentTime = new Date().getTime() / 1000;

          console.log("currentTime :" + currentTime);

          // if(currentTime<=begin_time){
          //   this.$router.go(-1)
          //   this.$message({
          //         message:'The test has not started!',
          //         type:'error',
          //         duration:5000,
          //         showClose:true
          //     })
          //   return
          // }

          if (currentTime > end_time) {
            this.$message({
              message: "The test has ended!",
              type: "error",
              duration: 5000,
              showClose: true,
            });
            this.$router.go(-1);
            reject(false);
          }

          const loading = this.$loading({
            lock: true,
            text: `需要等待时间${parseInt(result.remain_wait_time / 60)}分钟`,
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          setTimeout(() => {
            var diff = end_time - currentTime;
            var last_time = parseInt(diff);
            this.startCountDown(last_time);
            loading.close();
            resolve(true);
          }, result.remain_wait_time * 1000);
        });
      });
    },
    checkStudentExam() {
      checkIsUploadTest(this.template_id, getUserId(), getProjectCode()).then(
        (res) => {
          var result = res.data;

          if (result) {
            this.canExam = false;
            console.log("---sorry--");
            this.clearCountDown();
          }
        }
      );
    },
    doSubmitAnswer() {
      if (!this.checkDeviceCanWork()) {
        return;
      }
      if (!this.checkCanExam()) {
        return;
      }
      this.showSubmitAnswerDialog = true;
    },
    handleClose() {
      this.messageDrawer = false;
      this.showTeamChatDrawer = false;
    },
    showRedMessage() {
      getMyMessages(getUserId(), getProjectCode()).then((res) => {
        this.traversalData(res.data.data);
      });
      console.log(11);
    },
    traversalData(newData) {
      var haveNoReply = false;
      var newTip = false;

      for (var i = 0; i < newData.length; i++) {
        var message = newData[i];
        if (message.message_reply_id == "") {
          haveNoReply = true;
        }

        if (i < this.messageList.length) {
          var oldmessage = this.messageList[i];

          if (message.message_reply_id == oldmessage.message_reply_id) {
            console.log("是一致的");
          } else {
            console.log("是不一致的");
            newTip = true;
          }
        }
      }

      console.log("是否需要刷新", haveNoReply);
      console.log("是否需要提示新消息", newTip);

      if (haveNoReply == false) {
        clearInterval(this.timer);
      }

      if (newTip == true) {
        this.showRedTip = true;
      } else {
        this.showRedTip = false;
      }

      this.messageList = newData;
    },
    showMessage() {
      getMyMessages(getUserId(), getProjectCode()).then((res) => {
        // console.log(1111,res)
        this.messageList = res.data.data;
      });
      this.messageStr = "";
      this.messageDrawer = true;
      this.showRedTip = false;
      // clearInterval(this.timer);
    },
    getMyMessages() {
      getMyMessages(getUserId(), getProjectCode()).then((res) => {
        // console.log(1111,res)
        this.messageList = res.data.data;
      });
    },
    sendMessage() {
      if (this.messageType == "Please select your message topic") {
        this.$message({
          message: "Please select your message topic",
          type: "error",
          duration: 5000,
          showClose: true,
        });
        return;
      }
      if (this.messageStr == "") {
        this.$message({
          message: "Please input your message content",
          type: "error",
          duration: 5000,
          showClose: true,
        });
        return;
      }

      sendMessage(
        getUserId(),
        this.messageType,
        "student",
        this.messageStr,
        getProjectCode()
      ).then((res) => {
        this.messageStr = "";
        this.messageType = "Please select your message topic";

        this.$message({
          message: "Successfully sent",
          type: "success",
          duration: 5000,
          showClose: true,
        });

        this.getMyMessages();
        // this.timer = setInterval(this.showRedMessage, 5000);
      });
    },
    showBigPic(path) {
      this.currentSelectedPicPath = path;
      this.showBigPicDialog = true;
    },
    doSelectQuestion(index) {
      this.currentSelectedQuestionIndex = index;
      this.currentSelectedQuestion = this.questionList[index];
      this.showDrawer = false;
      this.resetViewPosition();
    },
    showMenu() {
      if (!this.checkDeviceCanWork()) {
        return;
      }
      if (!this.checkCanExam()) {
        return;
      }
      var choiceList = [];
      var noChoiceList = [];
      var temp = this.drawerQuestionList;
      var total = temp.length;
      var answered = 0;
      for (var i = 0; i < temp.length; i++) {
        var info = temp[i];
        var tempInfo = {
          question_question: info["question_question"],
          parentIndex: i,
        };
        if (info.choice !== "") {
          answered++;
          choiceList.push(tempInfo);
        } else {
          noChoiceList.push(tempInfo);
        }
      }
      this.drawerAnswerInfo = "Answered：" + answered + "/" + total;
      // drawerAnswerInfo
      this.drawerChoiceQuestionList = choiceList;
      this.drawerNoChoiceQuestionList = noChoiceList;
      this.showDrawer = true;
    },
    showPreQuestion() {
      if (!this.checkDeviceCanWork()) {
        return;
      }
      if (!this.checkCanExam()) {
        return;
      }
      // this.resetViewPosition()
      var index = this.currentSelectedQuestionIndex;
      index--;
      if (index < 0) {
        this.$message({
          message: "This is the first question",
          type: "warning",
          duration: 5000,
          showClose: true,
        });
        return;
      }
      this.currentSelectedQuestionIndex = index;
      this.currentSelectedQuestion = this.questionList[index];
    },
    showNextQuestion() {
      if (!this.checkDeviceCanWork()) {
        return;
      }
      if (!this.checkCanExam()) {
        return;
      }
      // this.resetViewPosition()
      var index = this.currentSelectedQuestionIndex;
      index++;
      if (index >= this.examQuestionSize) {
        this.$message({
          message: "This is the last question",
          type: "warning",
          duration: 5000,
          showClose: true,
        });
        return;
      }
      this.currentSelectedQuestionIndex = index;
      this.currentSelectedQuestion = this.questionList[index];
    },
    itemSelectQuestion(index) {
      if (!this.checkDeviceCanWork()) {
        return;
      }
      if (!this.checkCanExam()) {
        return;
      }

      this.currentSelectedQuestionIndex = index;
      this.currentSelectedQuestion = this.questionList[index];
    },
    showPreImage() {
      if (!this.checkDeviceCanWork()) {
        return;
      }
      if (!this.checkCanExam()) {
        return;
      }
      this.resetViewPosition();
      var index = this.currentSelectedImageIndex;
      index--;
      if (index < 0) {
        this.$message({
          message: "This is the first page",
          type: "warning",
          duration: 5000,
          showClose: true,
        });
        return;
      }
      this.currentSelectedImageIndex = index;
    },
    showNextImage() {
      if (!this.checkDeviceCanWork()) {
        return;
      }
      if (!this.checkCanExam()) {
        return;
      }
      this.resetViewPosition();
      var index = this.currentSelectedImageIndex;
      index++;
      if (index >= this.examImageSize) {
        this.$message({
          message: "This is the last page",
          type: "warning",
          duration: 5000,
          showClose: true,
        });
        return;
      }
      this.currentSelectedImageIndex = index;
    },
    goBack() {
      if (this.forceShutDown) {
        this.$router.go(-1);
        return;
      }

      if (this.showAnswerResultClick) {
        this.$router.go(-1);
        return;
      }

      if (this.deviceStatus != 0 || !this.canExam) {
        this.$router.go(-1);
        return;
      }

      this.$confirm(
        "You are currently in a test. If you exit from the current page, your test wil be automatically ended. Are you sure you want to exit?",
        "Reminder",
        {
          confirmButtonText: "Confirm to EXIT",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.quizTrue();
        })
        .catch(() => {
          this.pushHistory();
        });
      // this.$alert('当前正在进行考试，退出后，考试试卷会自动提交，您确定要退出考试？', '提示', {
      //   confirmButtonText: '确定',
      //   callback: () => {
      //     // this.$router.go(-1)
      //     this.quizTrue()
      //   }
      // });
    },
    quizTrue() {
      this.$confirm("Are you sure you want to exit?", "Reminder", {
        confirmButtonText: "Confirm to EXIT",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.uploadAnswers();
        })
        .catch(() => {
          this.pushHistory();
        });

      // this.$alert('确定要退出考试？', '提示', {
      //   confirmButtonText: '确定',
      //   callback: () => {
      //     this.$router.go(-1)
      //   }
      // });
    },
    fetchData() {
      this.questionAnswers = {};
      var student_id = getUserId();

      getExerciseTemplateByStudent(
        this.template_id,
        student_id,
        this.project_code
      ).then((res) => {
        this.test_subject = res.data.data.test_subject;
        this.section_list_instruction = res.data.data.section_list_instruction
          ? res.data.data.section_list_instruction
          : "";
        this.exam_images = res.data.data.pic_arr;
        // this.examImageSize = this.exam_images.length
        var index = 0;

        this.sections = res.data.data.sections_item;

        // var sections = res.data.data.sections_item
        // for (var i = 0; i < sections.length; i++) {
        //   var section = sections[i]
        //   var questions_items = section['questions_item']
        //   for (var j = 0; j < questions_items.length; j++) {
        //     index++
        //     var pos = index;
        //     if (pos < 10) {
        //       pos = '0' + pos
        //     }
        //     var question = {
        //       question_id: questions_items[j]['_id']['$id'],
        //       question_index: pos,
        //       question_question: questions_items[j]['question_question'],
        //       question_optionA: questions_items[j]['question_optionA'],
        //       question_optionB: questions_items[j]['question_optionB'],
        //       question_optionC: questions_items[j]['question_optionC'],
        //       question_optionD: questions_items[j]['question_optionD'],
        //       question_optionE: questions_items[j]['question_optionE'],
        //       choice: questions_items[j]['user_answer']
        //     }
        //     this.questionList.push(question)
        //   }
        // }

        // this.drawerQuestionList = this.questionList
        // this.examQuestionSize = this.questionList.length
        // this.currentSelectedQuestionIndex = 0
        // this.currentSelectedQuestion = this.questionList[0]
        if (this.ishouldRefreshView) {
          this.showExamWith(this.sectionSelect);
        }
        this.ishouldRefreshView = false;
        this.scheduleHeartBeat();

        //数据加载后再打开抽屉，接收websocket中的全局答案
        this.openTextChat();
      });
    },
    startCountDown(seconds) {
      if (this.countDownThread) {
        return;
      }
      let totalTime = seconds;
      this.countDownThread = window.setInterval(() => {
        this.countDownTimes = this.formatSeconds(totalTime);
        this.remainStamp = totalTime;
        totalTime--;
        if (totalTime < 0) {
          window.clearInterval(this.countDownThread);
          this.$alert("Time out!", "Tips", {
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
            callback: () => {
              this.lockStatus = true;
            },
          });
        }
      }, 1000);
    },
    saveAnswer(choice, item) {
      if (!this.checkDeviceCanWork()) {
        return;
      }
      if (!this.checkCanExam()) {
        return;
      }
      let question_id = item.question_id;
      let index = this.currentSelectedQuestionIndex;
      let section_index = this.sectionSelect;
      this.questionAnswers[question_id] = { choice: choice, status: "no" };
      this.drawerQuestionList[index]["choice"] = choice;
      this.sections[section_index].questions_item[index].user_answer = choice;

      submitAnswer(
        question_id,
        this.template_id,
        choice,
        this.user_id,
        this.ip,
        this.project_code,
        this.test_event
      ).then((res) => {
        if (res.data.code === 0) {
          this.questionAnswers[question_id]["status"] = "yes";
          // 做题时把记录发至websocket服务器
          if (this.ws) {
            this.ws.send(
              JSON.stringify({
                question_id,
                group_id: this.group_id,
                choice,
                template_id: this.template_id,
                type: "answerSync",
                user_name: getUserName(),
                user_id: getUserId(),
              })
            );
          }
        }
      });
    },
    //此处查找题目id 然后同步答案
    onAnswerSync(e) {
      for (let question_id in e) {
        //循环所有的题目 如果找到该题目id就修改答案并跳出当前循环
        findQuestion: for (let i in this.sections) {
          let section = this.sections[i];
          for (let j in section["questions_item"]) {
            let question = section["questions_item"][j];
            if (question_id === question["_id"]["$id"]) {
              question["user_answer"] = e[question_id]["choice"];
              let questionIndex = this.questionList.findIndex(
                (x) => x.question_id === question_id
              );
              if (questionIndex !== -1) {
                this.questionList[questionIndex]["choice"] =
                  question["user_answer"];
              }
              // 如果为单条修改 就弹出message
              if (e[question_id]["isShowMessage"] === "message") {
                this.$message({
                  message: `${
                    e[question_id]["user_name"]
                  } changed the answer of Case${Number(i) + 1} Question ${
                    Number(j) + 1
                  } to ${question["user_answer"]}`,
                  duration: 5000,
                  showClose: true,
                });
              }
              break findQuestion;
            }
          }
        }
      }
    },
    uploadAnswers() {
      // var answerList = []

      // for (var j = 0; j < this.sections.length; j++) {

      //   var answerValue = this.sections[j];
      //   var questions_items = answerValue['questions_item']

      //   for (var i = 0; i < questions_items.length; i++) {
      //       var answerDetailValue = questions_items[i];
      //       answerList.push(answerDetailValue['user_answer'])

      //   }

      // }
      let temp_no_answers = {};
      var questionIds = Object.keys(this.questionAnswers);
      questionIds.forEach((key) => {
        var question = this.questionAnswers[key];
        if (question["status"] == "no") {
          temp_no_answers[key] = question["choice"];
        }
      });

      var project_code = getProjectCode();
      uploadAnswersV3(
        project_code,
        this.user_id,
        this.template_id,
        this.app_id,
        this.ip,
        this.test_event,
        "team",
        temp_no_answers
      ).then((res) => {
        if (this.currentAudio) {
          this.currentAudio.pause();
        }
        if (res.data.code === 0) {
          this.$message({
            message: "upload success",
            type: "success",
            duration: 5000,
            showClose: true,
          });
          this.forceShutDown = true;
          this.$router.go(-1);

          // this.showAwardDialog(res.data.score);
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
            duration: 5000,
            showClose: true,
          });
          this.forceShutDown = true;
          this.$router.go(-1);
        }
      });
    },
    showAwardDialog(data) {
      this.answerResultDta = data;
      this.showAnswerResultDialog = true;
    },
    formatSeconds(endTime) {
      let secondTime = parseInt(endTime);
      let min = 0;
      let h = 0;
      let result = "";
      if (secondTime > 60) {
        min = parseInt(secondTime / 60);
        secondTime = parseInt(secondTime % 60);
        if (min > 60) {
          h = parseInt(min / 60);
          min = parseInt(min % 60);
        }
      }
      result = `${h.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${secondTime.toString().padStart(2, "0")}`;
      return result;
    },
    closeView() {
      this.showAnswerResultClick = true;
      this.$router.go(-1);
      console.log("aaa", 111);
    },
    onCorrectSessionPicSync(e) {
      for (let index in this.sections) {
        if (this.sections[index]._id.$id === e._id.$id) {
          this.sections[index].pic_arr = e.pic_arr;
          if (Number(index) === this.sectionSelect) {
            this.exam_images = e.pic_arr.map((item) => "https://" + item);
          }
        }
      }
    },
    onCorrectQuestionSync(e) {
      let question_id = e._id.$id;
      //循环所有的题目 如果找到该题目id就修改答案并跳出当前循环
      findQuestion: for (let i in this.sections) {
        let section = this.sections[i];
        for (let j in section["questions_item"]) {
          let question = section["questions_item"][j];
          if (question_id === question["_id"]["$id"]) {
            question["question_answer"] = e.question_answer;
            question["question_optionA"] = e.question_optionA;
            question["question_optionA_string"] = e.question_optionA_string;
            question["question_optionB"] = e.question_optionB;
            question["question_optionB_string"] = e.question_optionB_string;
            question["question_optionC"] = e.question_optionC;
            question["question_optionC_string"] = e.question_optionC_string;

            question["question_optionD"] = e.question_optionD;
            question["question_optionD_string"] = e.question_optionD_string;
            question["question_question"] = e.question_question;
            question["question_question_string"] = e.question_question_string;

            let questionIndex = this.questionList.findIndex(
              (x) => x.question_id === question_id
            );
            if (questionIndex !== -1) {
              this.questionList[questionIndex]["question_answer"] =
                e.question_answer;
              this.questionList[questionIndex]["question_optionA"] =
                e.question_optionA;
              this.questionList[questionIndex]["question_optionA_string"] =
                e.question_optionA_string;
              this.questionList[questionIndex]["question_optionB"] =
                e.question_optionB;
              this.questionList[questionIndex]["question_optionB_string"] =
                e.question_optionB_string;
              this.questionList[questionIndex]["question_optionC"] =
                e.question_optionC;
              this.questionList[questionIndex]["question_optionC_string"] =
                e.question_optionC_string;
              this.questionList[questionIndex]["question_optionD"] =
                e.question_optionD;
              this.questionList[questionIndex]["question_optionD_string"] =
                e.question_optionD_string;
              this.questionList[questionIndex]["question_question"] =
                e.question_question;
              this.questionList[questionIndex]["question_question_string"] =
                e.question_question_string;
            }

            break findQuestion;
          }
        }
      }
    },
    checkCanExam() {
      if (this.DEBUG_DEV) {
        return true;
      }
      if (this.canExam) {
        return true;
      }
      this.$message({
        message:
          "The system detects your camera and screen sharing is disrupted. You can not continue under this condition.",
        type: "error",
        duration: 5000,
        showClose: true,
      });
      return false;
    },
    showExchangeTip() {
      this.$alert(
        "该切屏次数仅为系统智能记录次数，监考人员将根据实时记录的屏幕共享画面进行人工复核。最终切屏记录及是否认定为违规行为以人工复核结果为准。若因为非故意作弊原因（包括弹窗、意外点击屏幕外区域等），可继续正常进行考试",
        "提示",
        {
          confirmButtonText: "确定",
          callback: (action) => {},
        }
      );
    },
    checkDeviceCanWork() {
      if (this.deviceStatus == 0) {
        return true;
      }
      if (this.deviceStatus == 1) {
        this.$message({
          message: `Your browser does not support camera sharing.`,
          type: "error",
          duration: 5000,
          showClose: true,
        });
        return false;
      }
      if (this.deviceStatus == 2) {
        this.$message({
          message: `Your browser does not support screen sharing`,
          type: "error",
          duration: 5000,
          showClose: true,
        });
        return false;
      }
      if (this.deviceStatus == 3) {
        this.$message({
          message: `Camera sharing authorization failed`,
          type: "error",
          duration: 5000,
          showClose: true,
        });
        return false;
      }
      if (this.deviceStatus == 4) {
        this.$message({
          message: `Screen sharing authorization failed`,
          type: "error",
          duration: 5000,
          showClose: true,
        });
        return false;
      }
      return true;
    },
    resetViewPosition() {
      var exam_image = document.getElementById("exam-image");
      var scrollTop = exam_image.scrollTop;
      scroll(scrollTop, 0, exam_image);
    },
    clearCountDown() {
      this.doUploadHeartBeat(false);
      if (this.heartBeatTimer) {
        window.clearInterval(this.heartBeatTimer);
        this.heartBeatTimer = null;
      }
    },
    refreshVisible() {
      getTestVisibleChangeCount(
        this.template_id,
        getUserId(),
        getProjectCode()
      ).then((res) => {
        let count = res.data.data;
        this.screenChangeCount = count;
      });
    },
    scheduleHeartBeat() {
      this.heartBeatTimer = window.setInterval(() => {
        this.doUploadHeartBeat(true);
      }, 30000);
    },
    doUploadHeartBeat(status) {
      uploadTestHeartBeat(getUserId(), this.template_id, status).then(
        (res) => {}
      );
    },
  },
  beforeDestroy() {
    if (this.countDownThread) {
      window.clearInterval(this.countDownThread);
      this.countDownThread = null;
    }
    window.removeEventListener("offline", this.networkErrorCallback);
    window.removeEventListener("online", this.networkSuccessCallback);
    window.removeEventListener("popstate", this.goBack, false);
    this.clearCountDown();
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style>
.showAnswerResultDialogStyle {
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  padding: 0;
  box-shadow: 0px 0px 0px 0px transparent;
}

.showMenuDialogStyle {
  background-color: transparent;
  padding: 0;
  box-shadow: 0px 0px 0px 0px transparent;
}

.showSubmitAnswerDialogStyle {
  background-color: transparent;
  padding: 0;
  box-shadow: 0px 0px 0px 0px transparent;
}
</style>

<style scoped lang="less">
.exam-btn-qa {
  margin-left: 15px;
  cursor: pointer;
  font-size: 18px;
  color: white;
  font-family: "Microsoft YaHei-Bold";
  box-sizing: border-box;
  background-color: #f28801;
  font-weight: bold;
  padding: 6px 12px;
  border-radius: 6px;
}
.exam-btn-exchange-lang {
  margin-left: 15px;
  cursor: pointer;
  font-size: 18px;
  color: #4267c0;
  font-family: "Microsoft YaHei-Bold";
  box-sizing: border-box;
  background-color: #b1ccff;
  font-weight: bold;
  padding: 6px 12px;
  border-radius: 18px;
}

/deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #2d50b0 !important;
}
/deep/ .el-radio__input.is-checked .el-radio__inner {
  background: #2d50b0 !important;
  border-color: #2d50b0 !important;
}

/deep/ .el-tabs__item {
  color: white !important;
}

/deep/ .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  color: white !important;
}

.drawer-question-list {
  width: 100%;
  height: 100%;
  background-color: #2d50b0;
  display: flex;
  flex-direction: column;
}

.drawer-answered {
  margin-left: 15px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  width: 80px;
  color: white;
  background-color: #2d50b0;
  border-radius: 17px;
  font-weight: 600;
  font-size: 16px;
  font-family: Montserrat-SemiBold;
}

.drawer-question-item-root {
  padding: 15px 15px 0 15px;
  cursor: pointer;
  background: #2d50b0;

  .drawer-question-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    color: white;
    font-size: 20px;
    background: #2d50b0;
    padding: 12px;
    box-sizing: border-box;
    font-family: Montserrat-SemiBold;
    border-radius: 16px;
  }

  .selected {
    color: #2d50b0;
    font-family: Montserrat-SemiBold;
    background: #ffffff;
    border-radius: 16px;
  }
}

.drawer-question-item-root:last-child {
  padding-bottom: 30px;
}

.exam-empty-width-view {
  width: 2.184vw;
  height: 100%;
}

.exam-empty-height-view {
  height: 2.184vw;
  width: 100%;
}

.exam-question-content {
  // padding: 12px;
  margin-top: 20px;
  height: calc(100% - 40px);
  // width: 100%;
  // background-color: red;
  box-sizing: border-box;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
}

.exam-question-content::-webkit-scrollbar {
  display: none;
}

.exam-image {
  margin-top: 20px;
  // padding: 12px;
  height: calc(100% - 40px);
  width: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
}

.exam-image::-webkit-scrollbar {
  display: none;
}

.returnStyle {
  font-family: Montserrat-SemiBold;
  cursor: pointer;
  position: absolute;
  // display: flex;
  // margin-bottom: 10px;
  // align-items: center;
  bottom: 40px;
  margin-left: 12%;
  font-weight: 600;
  width: 140px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  // align-content: center;
  // align-items: center;
  border-radius: 15px;
  background-color: #003262;
  color: white;
}

.infoStyle {
  display: flex;
  border: 1px solid #003262;
  border-radius: 16px;
  color: #2d50b0;
  margin-left: 20px;
  margin-top: -25px;
}

.infoImg {
  width: 14px;
  height: 14px;
}

.box {
  display: flex;
  justify-content: left;
  margin-left: 80px;
  margin-right: 80px;
  margin-top: 20px;
  // height: 250px;
  // background-color: #FD5900;
  height: 20vh;
  overflow-x: hidden;
  overflow-y: scroll;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: left;
  align-content: left;
  text-align: left;
  float: left;
}

.child {
  // width: 50px;
  // background-color: aqua;
  height: 30px;
  padding-left: 10px;
}

.awardSubTitle {
  text-align: left;
  color: #2d50b0;
  font-size: 15px;
  margin-top: 20px;
  margin-left: 80px;
}

.awardLine {
  background-color: #00359f;
  margin-left: 80px;
  margin-right: 80px;
  height: 1px;
  margin-top: 10px;
}

.awardTitle1 {
  font-family: Montserrat-SemiBold;
  font-weight: bold;
  text-align: center;
  color: #0036a0;
  font-size: 30px;
  margin-top: 40px;
  margin-left: 80px;
  margin-right: 80px;
}

.scoreLab1 {
  font-family: Montserrat-SemiBold;
  margin-top: 20px;
  margin-right: 10px;
  height: 40px;
  //   background-color: rebeccapurple;
  text-align: center;
  font-size: 10px;
  color: #0036a0;
  font-style: italic;
  // float: right;
}

.scoreLab2 {
  font-family: Montserrat-SemiBold;
  margin-top: 35px;
  // margin-right: 80px;
  height: 40px;
  //   background-color: rebeccapurple;
  text-align: center;
  color: #0f429e;
  font-size: 25px;
  // float: right;
}

.awardTitle {
  font-family: Montserrat-SemiBold;
  font-weight: bold;
  text-align: center;
  color: #0f429e;
  font-size: 40px;
  margin-top: 60px;
  padding-top: 60px;
}

.answer-result-root {
  margin: 50px auto;
  padding: 0;
  background-image: url("../../assets/awardDetailBg1.png");
  width: 90%;
  height: 70vh;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  position: relative;
  border-radius: 20px;
}

.menu-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
}

.submitAnswer-root {
  position: relative;
  margin: 0 auto;
  padding: 0;
  width: 40vw;
  height: 40vh;
  background-image: url("../../assets/bg_submit_answer.png");
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  border-radius: 20px;
}

.option {
  cursor: none;
  text-align: left;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1vw;
  margin-top: 10px;
  border-radius: 10px;
  font-weight: bold;
  color: #072d76;
  font-family: Montserrat-SemiBold;
}

.div_p {
  font-family: Montserrat-SemiBold;
  font-size: 1vw;
  display: inline-block;
  color: #072d76;
  padding: 5px;
  background-color: white;

  .selected {
    color: white;
    padding: 5px;
    background-color: #072d76;
  }
}

.questionTitle {
  cursor: none;
  font-family: Montserrat-SemiBold;
  color: #072d76;
  font-size: 1.2vw;
  font-weight: bold;
  text-align: left;
}
.exam-goto-page {
  cursor: pointer;
  text-align: center;
  line-height: 6.44vh;
  width: 6.2vw;
  height: 100%;
  background-color: #2d50b0;
  font-family: "Microsoft YaHei-Bold";
  color: white;
  font-size: 1.248vw;
  font-weight: bold;
}
.exam-menu {
  cursor: pointer;
  text-align: center;
  line-height: 6.44vh;
  width: 5.2vw;
  height: 100%;
  background-color: #2d50b0;
  font-family: "Microsoft YaHei-Bold";
  color: white;
  font-size: 1.248vw;
  font-weight: bold;
}

.exam-page-bottom {
  width: 100%;
  height: 6.44vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #ffffff;
  box-shadow: 0px -1px 24px 1px rgba(0, 14, 138, 0.1);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}

.exam-page-bottom-text {
  cursor: pointer;
  font-size: 1.144vw;
  font-family: Microsoft YaHei-Bold;
  font-weight: bold;
  color: #2d50b0;
}

.exam-content-page {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  height: 100%;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 16px;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: scroll;
}

.exam-submit {
  margin-left: 3.264vw;
  cursor: pointer;
  font-size: 1.144vw;
  color: white;
  height: 4.416vh;
  font-family: "Microsoft YaHei-Bold";
  box-sizing: border-box;
  background-color: #f28801;
  font-weight: bold;
  line-height: 4.416vh;
  padding-left: 1.4vw;
  padding-right: 1.4vw;
  border-radius: 1.248vw;
}

.exam-count-down {
  background-image: url("../../assets/exam-count-down.png");
  width: 10.76vw;
  height: 11.672vh;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: contain;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.exam-top-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  height: 176px;
}

.exam-content {
  margin-top: -1px;
  width: calc(100% - 300px);
  // background-image: url("../../assets/exam-content-bg.png");
  margin-left: 150px;
  height: calc(100vh - 150px);
  background-repeat: no-repeat;
  background-color: white;
  background-size: cover;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  -moz-user-select: none;
  user-select: none;
}

.exam-top {
  background-image: url("../../assets/topBanner.png");
  height: 18vh;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 2.184vw;
}

.exam-root {
  width: 100%;
  height: 100vh;
  position: relative;
  margin: 0 auto;
  text-align: center;
  user-select: none;
}

.messageList {
  width: 500px;
  height: 90%;
  left: 5%;
  background-color: white;
  margin-top: 30px;
  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: 10px;
  text-align: left;
}

.inputStyle {
  width: 100%;
  height: 60px;
}

.inputSelect {
  margin-left: 20px;
  width: 150px;
  height: 40px;
}

.inputStyle1 {
  width: 60%;
  height: 40px;
  margin-left: 20px;
  margin-top: 10px;
}

.sendStyle {
  margin-left: 10px;
  background-color: #00359f;
  color: white;
  font-family: Montserrat-SemiBold;
}

.messageList {
  width: 90%;
  height: 90%;
  background-color: white;
  margin-top: 30px;
  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: 10px;
  text-align: left;
}

.list {
  overflow-x: hidden;
  overflow-y: scroll;
}

.messageDetail {
  margin-left: 20px;
  margin-top: 30px;
  width: 90%;
  font-family: Montserrat-SemiBold;
  // height: 50px;
  text-align: left;
}

.studentmessageList {
  width: calc(80% + 30px);
  height: calc(90% - 90px);
  background-color: #072d76;
  margin-top: 30px;
  text-align: left;
}

.redTip {
  position: absolute;
  margin-left: 3vw;
  margin-top: 5px;
  background-color: red;
  width: 12px;
  height: 12px;
  border-radius: 6px;
}

.topBanner {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
}
.topBanner1 {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  background-repeat: no-repeat;
  background-color: #d9661f;
  background-size: cover;
}
.logoutStyle {
  right: 200px;
  position: absolute;
  z-index: 3;
  width: 60px;
  color: white;
  height: 15px;
  border: 1px solid white;
  text-align: center;
  font-size: 10px;
  line-height: 15px;
  font-family: Montserrat-SemiBold;
}
.countryStyle {
  position: absolute;
  margin-top: -20px;
  margin-left: 150px;
  font-size: 20px;
  color: white;
  height: 25px;
  font-family: Montserrat-SemiBold;
}

.nameStyle {
  position: absolute;
  margin-top: 25px;
  margin-left: 150px;
  font-size: 13px;
  color: white;
  height: 25px;
  font-family: Montserrat-SemiBold;
}

.changePMNumStyle {
  position: absolute;
  // margin-left:40px;
  // margin-right: 300px;
  margin-top: 10px;
  right: 350px;
  margin-bottom: 10px;
  // background-color:white;
  padding: 6px;
  border-radius: 4px;
  font-size: 12px;
  color: white;
  font-family: Montserrat-SemiBold;
}
.countDownNewStyle {
  position: absolute;
  right: 200px;
  color: white;
  font-size: 1.56vw;
  margin-left: -1vw;
  // margin-top: 1.656vh;
  letter-spacing: 0.16vw;
  font-family: Montserrat-SemiBold;
}

.exam-submit1 {
  position: absolute;
  right: 100px;
  // float: left;
  // margin-left: 3.264vw;
  // cursor: pointer;
  font-size: 1.144vw;
  color: white;
  height: 30px;
  width: 80px;
  font-family: Montserrat-SemiBold;
  box-sizing: border-box;
  background-color: #f28801;
  font-weight: bold;
  line-height: 30px;
  // padding-left: 1.4vw;
  // padding-right: 1.4vw;
  border-radius: 15px;
}
.inviStyle {
  position: absolute;

  text-align: center;
  align-items: center;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.bottomView {
  position: relative;
  /* z-index: 1; */
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  background-repeat: no-repeat;
  background-color: #003262;
  background-size: cover;
}
.exam-menu-new {
  display: flex;
  align-items: center;
  position: absolute;
  padding-left: 150px;
}

.exam-menu-new .button {
  align-items: center;
  margin-right: 10px;
  width: 90px;
  height: 20px;
  line-height: 20px;
  color: white;
  background-repeat: no-repeat;
  background-color: #003262;
  background-size: cover;
  border: 1px solid white;
  font-family: Montserrat-SemiBold;
}
.exam-menu-new1 {
  position: absolute;
  margin-left: 270px;
  align-items: center;
  width: 60px;
  height: 20px;
  line-height: 20px;
  color: white;
  background-repeat: no-repeat;
  background-color: #003262;
  background-size: cover;
  border: 1px solid white;
}
.redTipNew {
  position: absolute;
  margin-left: 50px;
  margin-top: -5px;
  background-color: red;
  width: 12px;
  height: 12px;
  border-radius: 6px;
}
.wrapper {
  // margin-top: 20px;
  // list-style: none;
  // overflow: hidden;
  margin-left: 10px;
}
.liStyle {
  float: left;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  margin-left: 5px;
  margin-top: 5px;
  font-size: 10px;
  background-color: #003262;
  color: rgba(255, 255, 255, 0.4);
}
.liStyle1 {
  float: left;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  margin-left: 5px;
  margin-top: 5px;
  font-size: 10px;
  background-color: white;
  color: #003262;
  border: 1px solid #003262;
}
.lastClickStyle {
  margin-left: 15px;
  position: absolute;
  width: calc(50% - 20px);
  height: 29px;
  border: 1px solid #003262;
  color: #003262;
  text-align: center;
  line-height: 29px;
  font-family: Montserrat-SemiBold;
}
.nextClickStyle {
  position: absolute;
  margin-left: calc(50% + 5px);
  width: calc(50% - 20px);
  height: 30px;
  background-color: #003262;
  color: white;
  text-align: center;
  line-height: 30px;
  font-family: Montserrat-SemiBold;
}

.exam-list {
  margin-top: -1px;
  width: calc(100% - 300px);
  // background-image: url("../../assets/exam-content-bg.png");
  margin-left: 150px;
  height: calc(100vh - 150px);
  -moz-user-select: none;
  user-select: none;
  // background-repeat: no-repeat;
  background-color: white;
  // background-size: cover;
  // box-sizing: border-box;
  position: relative;
  // display: flex;
  // flex-direction: row;
  overflow-x: hidden;
  overflow-y: scroll;
}
.sessionStyle {
  position: relative;
  // position: absolute;
  width: 100%;
  height: 140px;
  margin-top: 20px;
  background-color: #f9f9f9;
}
.sesionNumStyle {
  position: absolute;
  margin-left: 15px;
  margin-top: 16px;
  font-size: 1px;
  color: #003262;
}
.sesionLineStyle {
  position: absolute;
  margin-left: 55px;
  margin-top: 20px;
  width: 1px;
  height: 30px;
  background-color: #dddddd;
}
.sesionNumStyle4 {
  position: absolute;
  font-family: Montserrat-SemiBold;
  margin-left: 23px;
  margin-top: 35px;
  height: 30px;
  font-weight: 600;
  // background-color: #DDDDDD;
  color: #003262;
}
.sesionNumStyle1 {
  position: absolute;
  font-family: Montserrat-SemiBold;
  margin-left: 65px;
  margin-top: 20px;
  font-size: 24px;
  font-weight: 800;
  color: #003262;
}
.sesionNumStyle2 {
  position: absolute;
  font-family: Montserrat-SemiBold;
  font-weight: 600;
  margin-left: 65px;
  margin-top: 15px;
  font-size: 15px;
  color: #003262;
}

.sesionNumStyle5 {
  position: absolute;
  font-family: Montserrat-SemiBold;
  font-weight: 600;
  margin-left: 65px;
  margin-top: 60px;
  font-size: 40px;
  color: #003262;
}
.sesionNumStyle3 {
  position: absolute;
  font-weight: 800;
  font-family: Montserrat-SemiBold;
  margin-left: 15px;
  // margin-right: 15px;
  margin-top: 55px;
  font-size: 20px;
  width: calc(100% - 30px);
  color: #003262;
  text-align: left;
}
.haveSelectSession {
  position: absolute;
  left: 20px;
  top: 120px;
  color: #003262;
  text-align: left;
  font-size: 20px;
  width: 150px;
}

.section_list_instruction {
  text-align: left;
  font-size: 14px;
  padding: 5px;
  margin: 10px 0;
  color: gray;
  font-family: Montserrat-SemiBold;
}
</style>
